import React, { useState } from "react"
import { Link } from "gatsby"
import {
  AppInfoProps,
  LogoProps,
  getIconPath,
  getImgPath,
  combineClassWithTxtCol,
} from "../models/models"
import { FaArrowRight, FaPlay } from "react-icons/fa"

export type AppInfoComponentProps = AppInfoProps & {
  isLanding: boolean
}

const AppStoreBtn: React.FunctionComponent<LogoProps> = props => {
  return (
    <button
      onClick={() => window.open(props.link, "_blank")}
      style={{ width: "100px", height: "30px" }}
      className="rounded overflow-hidden bg-black mr-4 focus:outline-none hover:opacity-75 transition duration-500 ease-in-out"
    >
      <img
        className="w-full h-full"
        alt={props.store.type.toString()}
        src={props.store.src}
      />
    </button>
  )
}

export const AppInfoImg: React.FunctionComponent<AppInfoComponentProps> = props => {
  return (
    <>
      <Link to={`/app/${props.tag}`}>
        <img
          className="cursor-pointer transition duration-500 ease-in-out hover:opacity-75"
          alt={props.title}
          src={getImgPath(
            props.isLanding ? props.src : props.srcDetails ?? props.src
          )}
        />
      </Link>
    </>
  )
}

export const AppInfoImgWithTrailer: React.FunctionComponent<AppInfoProps> = props => {
  const [trailer, setTrailer] = useState(false)

  return (
    <>
      {trailer ? (
        <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
          <iframe
            title={props.tag}
            className="top-0 left-0 absolute w-full h-full"
            src={props.trailer}
          ></iframe>
        </div>
      ) : (
        <div className="relative">
          <img
            className="cursor-pointer transition duration-500 ease-in-out hover:opacity-75"
            alt={props.title}
            src={getImgPath(props.srcDetails ?? props.src)}
          />
          {props.trailer && (
            <div
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(149,165,166,0.8)",
              }}
              className="cursor-pointer absolute flex justify-center items-center px-6 py-4 rounded"
              onClick={() => setTrailer(true)}
            >
              <FaPlay />
              <span className="ml-3">Trailer</span>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export const AppInfo: React.FunctionComponent<AppInfoComponentProps> = props => {
  return (
    <>
      <AppInfoImg {...props} />
      <div className="block md:flex justify-between pt-4">
        <div className="flex flex-col justify-between">
          <div className="flex">
            <img
              className="w-20 h-20 md:w-24 md:h-24"
              alt={props.title}
              src={`${getIconPath(props.src)}`}
            />
            <div className="flex flex-col justify-center md:justify-start ml-3">
              <p className="uppercase text-sm md:text-md">{props.title}</p>
              <p
                className={combineClassWithTxtCol(
                  "font-bold text-sm md:text-lg",
                  props.subtitleColor
                )}
              >
                {props.subtitle}
              </p>
            </div>
          </div>
          <div className="flex justify-center md:justify-start pt-5 md:pt-1">
            {props.stores.map((s, sIdx) => (
              <AppStoreBtn key={sIdx} {...s} />
            ))}
          </div>
        </div>
        <div className="py-5 md:py-0 md:w-1/2 text-justify flex flex-col justify-between">
          <p>{props.description}</p>
          <Link to={`/app/${props.tag}`}>
            <div className="flex items-center mt-4">
              <div className="text-gray-600 mr-2 transition transform duration-200 ease-in-out hover:translate-x-1">
                <FaArrowRight />
              </div>
              <p className="text-blue-500 underline hover:text-blue-600">
                Read more
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export const AppInfoGrid: React.FunctionComponent<AppInfoComponentProps> = props => {
  return (
    <>
      <AppInfoImg {...props} />
      <div className={`p-3 ${props.bgDescription}`}>
        <Link to={`/app/${props.tag}`}>
          <div className="cursor-pointer flex items-center transition transform duration-500 ease-in-out hover:-translate-y-1 hover:text-gray-800 text-white">
            <img
              className="w-20 h-20"
              alt={props.title}
              src={getIconPath(props.src)}
            />
            <div className="flex flex-col justify-center md:justify-start ml-3 pt-1">
              <p className="uppercase text-sm md:text-md">{props.title}</p>
              <p
                className={combineClassWithTxtCol(
                  "font-bold text-lg",
                  props.subtitleColor
                )}
              >
                {props.subtitle}
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}

export const AppInfoDetails: React.FunctionComponent<AppInfoProps> = props => {
  return (
    <div
      className={`px-4 md:px-32 py-10 flex flex-col items-center justify-center ${props.bgDescription}`}
    >
      <div className="flex">
        <img
          className="w-16 h-16"
          alt={props.title}
          src={getIconPath(props.src)}
        />
        <div className="flex flex-col justify-center md:justify-start ml-3 pt-1">
          <p className="font-bold text-xl md:text-2xl">{props.title}</p>
          <p
            className={combineClassWithTxtCol(
              "uppercase text-sm md:text-md",
              props.subtitleColor
            )}
          >
            {props.subtitle}
          </p>
        </div>
      </div>
      <p className="md:px-10 text-justify my-10">{props.description}</p>
      <AppInfoImgWithTrailer {...props} />
      <div className="flex justify-center md:justify-start pt-10">
        {props.stores.map((s, sIdx) => (
          <AppStoreBtn key={sIdx} {...s} />
        ))}
      </div>
    </div>
  )
}
